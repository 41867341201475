import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import AllInclusive from '@material-ui/icons/AllInclusive';
import { Text } from '../../../Components/General/Text';
import { ActionButton } from '../../../Components/InputFields/BrancherButton';
import { ApprovedPair } from './ApprovedPair';
import { BrancherDialog, IBrancherDialog } from '../../../Components/General/BrancherDialog';
import { EPairingTypes, IDraftedPairs } from '../../../store/reducers/PairingReducer';

interface ITargetUserPairsDialog extends Omit<IBrancherDialog, 'labelledBy' | 'title'> {
  targetUser: string;
  getUserTargetPairs: () => void;
  draftPair?: (pair: IDraftedPairs, pairingType: EPairingTypes) => void;
  targetUserPairs?: IDraftedPairs[];
}

export const TargetUserPairsDialog: React.FC<ITargetUserPairsDialog> = (props) => {
  const { setClose, targetUser, open, targetUserPairs, draftPair, getUserTargetPairs } = props;
  const [hasOverriddenAlgorithm, setHasOverriddenAlgorithm] = React.useState<boolean>(false);

  return (
    <BrancherDialog
      setClose={setClose}
      labelledBy={`${targetUser}'s possible pairs`}
      title={`${targetUser}'s possible pairs`}
      open={open}
      contentWidth={true}
    >
      <Grid item xs={12} container direction="column">
        <Grid item container alignItems="center">
          <Grid item>
            <Text
              variant="md"
              color="purple"
              marginBottom={50}
              fontWeight={700}
              display="inline"
              marginRight={30}
            >
              {!targetUserPairs?.length
                ? `${targetUser} has no possible pairs`
                : targetUserPairs?.length === 1
                ? `${targetUserPairs?.length} suggested pair`
                : `${targetUserPairs?.length > 25 ? `Top 25` : targetUserPairs.length} suggested pairs`}
            </Text>
          </Grid>
          <Grid item>
            <ActionButton
              startIcon={<AllInclusive color="secondary" />}
              onClick={() => {
                // setClose(false); TODO: Check
                setHasOverriddenAlgorithm(true);
                getUserTargetPairs();
              }}
            >
              Get all pairs (ignores algorithm)
            </ActionButton>
          </Grid>
        </Grid>
        {targetUserPairs?.length > 0 && (
          <>
            <Grid item container justifyContent="space-between">
              <Text
                variant="sm"
                color="purple"
                fontWeight={600}
                marginRight={20}
                marginTop={15}
                marginBottom={10}
              >
                Mentee
              </Text>
              <Text
                variant="sm"
                color="purple"
                fontWeight={600}
                marginLeft={20}
                marginTop={15}
                marginBottom={10}
              >
                Mentor
              </Text>
            </Grid>
            <Grid item container spacing={2}>
              {targetUserPairs?.map((pair, i) => (
                <ApprovedPair
                  pair={pair}
                  draftPair={draftPair}
                  key={i}
                  overrideAlgorithm={hasOverriddenAlgorithm}
                />
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </BrancherDialog>
  );
};
